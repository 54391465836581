export default {
  proxy_path: "https://5tg37e2jaa.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://22djz2k2ni.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyA5Ofaf2rUJ4Qp9Xo1YVA2rDP4-f0PQ3Es",
  main_company_term_id: "6",
  crm_base_url: "staging.emeco.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.emeco.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.emeco.forwoodsafety.com",
    APP_CLIENT_ID: "6otf2cq95blhgbup8mag47d4ra",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://fn39ia9e37.execute-api.us-west-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.emeco.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
